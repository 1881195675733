import axios from 'axios'
import Cookie from 'js-cookie'
import store from '@/state/store'

let user = {token: Cookie.get('_easyindustria_token')};
const API = `${process.env.VUE_APP_BASEURI}/api`;
store.dispatch('notification/clear', { root: true });

const http = axios.create({
  baseURL: API,
  headers: {
    Authorization: 'Bearer '+`${user.token}`,
    ContentType: 'application/json',
    Accept: 'application/json'
  }
});

http.interceptors.response.use(function (response) {
  return response;
},
function (error) {
  if (error.response) {
    const { data, status } = error.response
    if (status === 401 && data.message === 'Unauthenticated.') {
      store.dispatch('notification/error', 'Usuário não autenticado!', { root: true });
      localStorage.removeItem('user');
      Cookie.remove('_easyindustria_token');
      location.reload(true);
    }
    if (status === 403 && data.message === 'This action is unauthorized.') {
      store.dispatch('notification/error', 'Usuário não autorizado!', { root: true });
    }
  }
  return Promise.reject(error);
}
)

export { http, API }